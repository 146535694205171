<template>
<div></div>
</template>
<script>
import abbottAndApi from '@/apis/liff/v2/proj/abbott-and';
import LiffChecker from "@/utils/LiffChecker";

export default {
  mixins: [],
  components: {},
  async mounted() {
    const query = this.$route.query;
    this.source = query.source;
    this.phone = query.phone;
    this.coupon_code = query.couponCode;
    const checker = new LiffChecker(this.$route.params.orgCode);
    this.lineData = await checker.getLineData();
    await this.init();
  },
  data() {
    return {
      source: '',
      phone: '',
      coupon_code: '',
      lineData: {},
    };
  },
  computed: {},
  methods: {
    async init() {
      try {
        const res = await abbottAndApi.checkMemberByPhone(
          this.$route.params.orgCode,
          {
            phone: this.phone,
          }
        );
        if (res.data.is_member) {
          if (res.data.line_id == this.lineData.profile.userId) {
            this.submit();
          } else {
            alert('此手機與查詢會員不一致，請聯絡客服人員')
          }
        } else {
          this.submit();
        }
      } catch (e) {
        console.log(e);
        alert(e.response.data.message)
      }
    },
    async submit() {
      try {
        await abbottAndApi.sendCoupon(
          this.$route.params.orgCode,
          {
            line_id: this.lineData.profile.userId,
            phone: this.phone,
            coupon_code: this.coupon_code,
            source: this.source,
          }
        )
        this.$router.push({
          name: 'LiffProjAbbottAndThanks',
          query: {
            messages: "成功領取",
          },
        });
      } catch (e) {
        this.$router.push({
          name: 'LiffProjAbbottAndThanks',
          query: {
            messages: e.response.data.message,
          },
        });
      }
    },
  }
}
</script>

<style lang="scss"></style>
